import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import theme from '@styles/theme';
import { Button } from './components';

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 350px;

  .MuiTextField-root {
    margin-top: 24px;

    &:first-of-type {
      margin-top: 40px;
    }
  }

  ${Button} {
    margin-top: 40px;
    width: 100%;
  }
`;

const CssTextField = withStyles({
  root: {
    '& label': {
      color: theme.color.primary,
      fontFamily: theme.font.primary,
    },
    '& label.Mui-focused': {
      color: theme.color.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.color.primary,
    },
    '& input': {
      color: theme.color.primary,
      fontFamily: theme.font.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.color.primary,
        color: theme.color.primary,
      },
      '&:hover fieldset': {
        borderColor: theme.color.primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.color.primary,
      },
    },
  },
})(TextField);

class Form extends React.PureComponent {
  validate = values => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  onSubmit = async (values, { setSubmitting }) => {
    try {
      await addToMailchimp(values.email, {
        FNAME: values.firstName,
        LNAME: values.lastName,
      });
      setSubmitting(false);
      this.props.onSubmit(values);
    } catch (e) {
      alert('Something went wrong');
      setSubmitting(false);
      console.error(e);
    }
  };

  render() {
    return (
      <Formik
        initialValues={{ email: '', firstName: '', lastName: '' }}
        validate={this.validate}
        onSubmit={this.onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <FormWrapper onSubmit={handleSubmit}>
              <CssTextField
                type="email"
                label="Email"
                variant="outlined"
                required
                name="email"
                value={values.email}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <CssTextField
                label="First Name"
                variant="outlined"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <CssTextField
                label="Last Name"
                variant="outlined"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
              />
              <Button
                type="submit"
                disabled={isSubmitting || Object.keys(errors).length}
              >
                Join Beta
              </Button>
            </FormWrapper>
            <Button
              onClick={this.props.onCancel}
              className="secondary"
              style={{ marginTop: 16, width: '100%' }}
            >
              Cancel
            </Button>
          </>
        )}
      </Formik>
    );
  }
}

export default Form;
