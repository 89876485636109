import styled from 'styled-components';

export const Logo = styled.img`
  width: 60%;
  height: 60px;
  max-width: 350px;
`;

export const ScrollContainer = styled.div`
  overflow: auto;
  height: 100vh;
  
  @media(min-height: 800px) {
    overflow: hidden;
  }
`;

export const Container = styled.div`
  padding: 40px 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media(min-height: 800px) {
    height: 100vh;
  }

  &.display-enter,
  &.display-appear {
    opacity: 0;
    transition: all 600ms;
  }
  /* This is where we can add the transition*/
  &.display-enter-active,
  &.display-appear-active {
    opacity: 1;
    transition: all 600ms;
  }

  /* This fires as soon as the this.state.showList is false */
  &.display-exit {
    opacity: 0;
    transition: all 600ms;
  }
  &.display-exit-active {
    transition: all 600ms;
  }
`;

export const Content = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  @media(min-height: 800px) {
    flex: 1;
  }
`;

export const LinksWrapper = styled.div`
  margin: 32px 16px 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  max-width: 480px;
  width: 100%;

  a {
    margin: 4px 16px;
  }
`;

export const Badge = styled.img`
  height: 48px;
`;

export const Input = styled.input`
  padding: 8px;
  color: ${({ theme }) => theme.color.primary};
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.color.primary};
  outline: 0;
  flex: 1;
`;

export const Button = styled.button`
  margin-left: 16px;
  padding: 8px 32px;
  background-color: ${({ theme }) => theme.color.primary};
  color: ${({ theme }) => theme.color.backgroundColor};
  border-radius: 40px;

  &[disabled] {
    opacity: 0.4;
    cursor: unset;
  }

  &.secondary {
    color: ${({ theme }) => theme.color.primary};
    background-color: transparent;
  }
`;

export const MocksWrapper = styled.div`
  padding-top: 20px;
  margin-bottom: -20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  height: 45%;
  overflow: hidden;
  
  @media(max-height: 800px) {
    margin-bottom: -120px;
  }
`;

export const Mock = styled.img`
  width: 60%;
  min-width: 200px;
  max-width: 350px;
  min-height: 200px;

  &:last-of-type {
    margin-left: -120px;
    transform: translateY(10%);
  }
`;

export const Text = styled.p`
  margin-top: 32px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.primary};
  max-width: 520px;
  text-align: center;
  line-height: 1.3;
`;

export const Subtext = styled.p`
  margin-top: 24px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.primary};
  max-width: 350px;
  text-align: center;
  line-height: 1.2;
`;

export const H1 = styled.p`
  font-size: 56px;
  color: ${({ theme }) => theme.color.primary};
`;
