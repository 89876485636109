import React, { useContext, useState } from 'react';
import { FirebaseContext } from 'gatsby-plugin-firebase';
import CSSTransition from 'react-transition-group/CSSTransition';

import LogoImg from '@images/logo.svg';
import PlayMarketBadge from '@images/google-play-badge.svg';
import AppStoreBadge from '@images/app-store-badge.svg';
import HomeMock from '@images/mocks/home.png';
import CocktailDetailsMock from '@images/mocks/cocktail-details.png';
import { EVENT_NAME } from '@constants/analytics';
import Background from '@common/Background';

import {
  Container,
  Content,
  LinksWrapper,
  Logo,
  Mock,
  MocksWrapper,
  Text,
  Badge,
  Subtext,
  ScrollContainer,
} from './components';
import Form from './Form';

const STEPS = {
  INITIAL: 0,
  FORM: 1,
  THANK_YOU: 2,
};

const LINKS = {
  PLAY_MARKET:
    'https://play.google.com/store/apps/details?id=com.zasadnyy.jigger',
  APP_STORE: 'https://apps.apple.com/app/id1497308666',
};

function Header() {
  const [currentStep, setCurrentStep] = useState(STEPS.INITIAL);
  const firebase = useContext(FirebaseContext);

  const onPlayMarket = () => {
    firebase.analytics().logEvent(EVENT_NAME.PLAY_MARKET);
  };

  const onAppStore = () => {
    firebase.analytics().logEvent(EVENT_NAME.OPEN_APP_STORE);
  };

  const onSubmit = data => {
    setCurrentStep(STEPS.THANK_YOU);
    firebase.analytics().logEvent(EVENT_NAME.SUBMIT_FORM, data);
  };

  const onCancel = () => {
    setCurrentStep(STEPS.INITIAL);
    firebase.analytics().logEvent(EVENT_NAME.CANCEL_FORM);
  };

  return (
    <Background>
      <CSSTransition
        in={currentStep === STEPS.INITIAL}
        timeout={600}
        classNames="display"
        unmountOnExit
        appear
      >
        <ScrollContainer>
          <Container>
            <Content>
              <Logo src={LogoImg} alt="Jigger" />
              <Text>
                Explore a fascinating world of bartending in a new way. Learn
                what ingredients you need and how to mix your favorite
                manhattans, sours, mojitos, and thousands of other cocktails.
              </Text>
              <Subtext>
                Missing your favorite recipe or have a feedback or suggestion?
                Reach us at{' '}
                <a href="mailto:makeitjigger@gmail.com">
                  makeitjigger@gmail.com
                </a>
              </Subtext>
              <LinksWrapper>
                <a href={LINKS.APP_STORE} onClick={onAppStore} target="_blank">
                  <Badge src={AppStoreBadge} alt="App store" />
                </a>
                <a
                  href={LINKS.PLAY_MARKET}
                  onClick={onPlayMarket}
                  target="_blank"
                >
                  <Badge src={PlayMarketBadge} alt="Play Market" />
                </a>
              </LinksWrapper>
            </Content>
            <MocksWrapper>
              <Mock src={HomeMock} alt="Jigger" />
              <Mock src={CocktailDetailsMock} alt="Jigger" />
            </MocksWrapper>
          </Container>
        </ScrollContainer>
      </CSSTransition>

      <CSSTransition
        in={currentStep === STEPS.FORM}
        timeout={600}
        classNames="display"
        unmountOnExit
      >
        <Container>
          <Content>
            <Logo src={LogoImg} alt="Jigger" />
            <Form onSubmit={onSubmit} onCancel={onCancel} />
          </Content>
        </Container>
      </CSSTransition>
    </Background>
  );
}

export default Header;
